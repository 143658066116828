import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [randomNumber, setRandomNumber] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const generateRandomNumber = () => {
    const minValue = parseInt(min);
    const maxValue = parseInt(max);

    if (minValue === "" || maxValue === "") {
      alert("Enter the correct values for the minimum and maximum numbers.");
      return;
    }

    if (maxValue <= minValue) {
      alert("The maximum value of the number must be greater than the minimum value.");
      return;
    }

    const num = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
    setRandomNumber(num);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <div className={`App ${isDarkMode ? "dark" : ""}`}>
      <header>
        <h1>Random Number Generator</h1>
        <button onClick={toggleTheme} className="theme-toggle">
          {isDarkMode ? (
            <i className="fas fa-sun"></i>
          ) : (
            <i className="fas fa-moon"></i>
          )}
        </button>
      </header>
      <div className="input-container">
        <div className="input-group">
          <label htmlFor="min">Min:</label>
          <input
            id="min"
            type="number"
            value={min}
            onChange={(e) => setMin(e.target.value)}
          />
          <span className="input-info">min value must be less than max value</span>
        </div>
        <div className="input-group">
          <label htmlFor="max">Max:</label>
          <input
            id="max"
            type="number"
            value={max}
            onChange={(e) => setMax(e.target.value)}
          />
          <span className="input-info">max value must be greater than min value</span>
        </div>
        <button onClick={generateRandomNumber}>Generate</button>
      </div>
      {randomNumber !== null && (
        <div className="result-container">
          <h2>Generated Number:</h2>
          <div className="result">{randomNumber}</div>
        </div>
      )}
    </div>
  );
}

export default App;
